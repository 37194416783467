<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>教务中心</a-breadcrumb-item>
            <a-breadcrumb-item>班级相册</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <albums/>
        </div>
    </div>
</template>

<script>
    import albums from '@/views/market/xuetang/album'
    export default {
        name:'album',
        components: {
            albums,
        },
    }
</script>

<style lang="scss" scoped>

</style>